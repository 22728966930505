import "normalize.css";
import "../Style/Default.css";
import Vue from "vue";
import axios from "axios";
import { BaseUrl, SchoolType } from "./BaseUrl";
import { alertBox } from "./Alert";
import url from "domurl";
let UrlInfo = new url();
let ShowLogin = UrlInfo.query.ShowLogin;

window.onload = function () {
  if (window.navigator.userAgent.toLocaleLowerCase().indexOf("line") >= 0) {
    alertBox({
      Html: `<span class="text-red-700">不支援此瀏覽器<br>請按下確認鍵使用預設瀏覽器開啟</span><br>(建議使用Chrome、Firefox、Edge、Safari瀏覽器開啟本網站)`,
      OnOK: function () {
        location.href = "Default.html?openExternalBrowser=1";
      },
    });
  }
  let vue_object = new Vue({
    el: ".main",
    data: {
      SchoolType: SchoolType,
      Config: {
        SysCount: 0,
        Title: "",
      },
    },
    methods: {
      Init: function () {
        let my = this;
        let _Url = BaseUrl + `/api/Config/SysTotalCount/`;
        axios
          .get(_Url)
          .then(function (res) {
            // 請求異常
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }
            my.Config = res.data.Data;
            document.title = my.Config.Title;
            if (SchoolType == 1) {
              document.querySelector("#Entrance1").title =
                "一般智能資賦優異鑑定";
              document.querySelector("#Entrance2").title =
                "創造能力資賦優異鑑定";
            } else {
              document.querySelector("#Entrance1").title =
                "學術性向資賦優異鑑定";
              document.querySelector("#Entrance2").title =
                "創造能力資賦優異鑑定";
            }
          })
          .catch(function (err) {
            // 請求異常返回首頁
            alertBox({
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },

      changehref: function (sys_ID) {
        let my = this;
        let Identity_ID = sessionStorage.getItem("Identity");
        if (Identity_ID == 1) {
          window.location.href = "./Login_Index.html?System_Type_ID=" + sys_ID;
        } else {
          window.location.href =
            "./Index.html?System_Type_ID=" +
            sys_ID +
            (ShowLogin === "true" ? "&ShowLogin=true" : "");
        }
      },
    },
  });
  new Vue({
    el: ".header",
    data: {
      SchoolType: SchoolType,
    },
  });
  new Vue({
    el: ".footer",
    data: {
      SchoolType: SchoolType,
    },
  });
  new Vue({
    el: ".Type2_Time",
    data: {
      SchoolType: SchoolType,
    },
  });
  vue_object.Init();
};
